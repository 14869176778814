exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-os-js": () => import("./../../../src/pages/om-os.js" /* webpackChunkName: "component---src-pages-om-os-js" */),
  "component---src-pages-privatliv-js": () => import("./../../../src/pages/privatliv.js" /* webpackChunkName: "component---src-pages-privatliv-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tilmelding-js": () => import("./../../../src/pages/tilmelding.js" /* webpackChunkName: "component---src-pages-tilmelding-js" */),
  "component---src-pages-undervisning-js": () => import("./../../../src/pages/undervisning.js" /* webpackChunkName: "component---src-pages-undervisning-js" */)
}

